body {
  margin: 0;
  font-family: 'Monaco', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*TODO: Cooler background, maybe something moving in a pattern and terminal is transparent */
  background-image: url(../public/wallpaper.jpg);
}

@font-face {
	font-family: 'Monaco';
	src: local('Monaco'), url(./fonts/Monaco.ttf) format('truetype');
	/* other formats include: 'woff2', 'truetype, 'opentype',
	'embedded-opentype', and 'svg' */
}

code {
  font-family: Monaco, source-code-pro, Menlo, Consolas, 'Courier New',
    monospace;
}
