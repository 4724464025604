.row {
	display: flex;
}
.social {
	background-color: #3898a770;
	border-radius: 10pt;
	width: 35%;
	text-align: center;
	border: 2px solid #fff;
	margin: 5%;
	padding: 5pt;
}
.social > img {
	height: 65pt;
}
.mobileColors {
	color: #f0ead2;
	background-color: #2b2d42d9;
}
.mobileWindow {
	padding: 5pt;
	padding-bottom: 25pt;
	border-radius: 5px;
	border: 2px solid rgb(0, 180, 216);
	box-shadow: 4px 4px 20px 12px #2a62b5;
	margin: auto;
	width: 90%;
}
.hidden {
	display: none;
}
.indented {
	margin-left: 30pt;
}
.githubStats {
	padding: 10pt;
}
.skillBubble {
    font-size: 10pt;
    padding: 3pt;
    border: solid 2px #00b4d8;
    border-radius: 15pt;
    margin: 2pt;
    background-color: #244968;
	white-space: nowrap;
	height: 12pt;
}
.skillName {
	font-size: 18pt;
}
.skillSetWrapper {
	margin: 5pt;
}
.skillWrapper {
	padding: 2pt;
    display: flex;
    justify-content: space-between;
    width: 100%;
	border-bottom: 1px dashed;
}
.skillBubbleWrapper {
	display: flex;
	align-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
}
.skillComment {
	font-size: 14pt;
}
.catOutput {
	margin: 0 20%;
}
.directory {
	color: #24fab2;
}
.footnote {
	font-size: 10pt;
}
