.commandHistory {
	overflow-y: scroll;
	max-height: 95%;
	margin: 0;
	padding: 0;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	display: flex;
	flex-direction: column-reverse;
}
.commandHistory a {
	color: #7ebad7;
}
.commandHistory::-webkit-scrollbar {
	display: none;
}
.commandHistory li {
	list-style-type: '';
}
input, input:focus-visible {
	outline: none;
	border:none;
	background-image:none;
	background-color:transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.PS1 {
	color: #ffb703;
}
.commandLine {
	width: 100%;
}
.commandLine input {
	font-size: 20pt;
	font-family: 'Monaco';
	max-width: 80%;
	min-width: 50%;
}
.terminal {
	position: relative;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	padding: 0 0.4rem;
	width: 100%;
}
.terminalColors {
	color: #f0ead2;
	background-color: #2b2d42d9;
}
.terminalColors input {
	color: #f0ead2;
}
.hidden {
	display: none;
}
.indented {
	margin-left: 30pt;
}
.githubStats {
	padding: 10pt;
}
.skillBubble {
    font-size: 10pt;
    padding: 3pt;
    border: solid 2px #00b4d8;
    border-radius: 15pt;
    margin: 2pt;
    background-color: #244968;
	white-space: nowrap;
	height: 12pt;
}
.skillName {
	font-size: 18pt;
}
.skillSetWrapper {
	margin: 5pt;
}
.skillWrapper {
	padding: 2pt;
    display: flex;
    justify-content: space-between;
    width: 100%;
	border-bottom: 1px dashed;
}
.skillBubbleWrapper {
	display: flex;
	align-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-end;
}
.skillComment {
	font-size: 14pt;
}
.catOutput {
	margin: 0 20%;
}
.directory {
	color: #24fab2;
}
