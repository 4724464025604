.floatingDiv {
	width: 80%;
    height: 500pt;
    border-radius: 5px;
    border: 2px solid rgb(0, 180, 216);
	padding-bottom: 25pt;
	position: absolute;
	box-shadow: 4px 4px 20px 12px #2a62b5
}
.body {
	height: 100%;
	overflow: hidden;
	display: flex;
}
.header {
    height: 25pt;
    background-color: #5ca5a5;
    line-height: 25pt;
    padding: 0 0.4rem;
}
.onTop {
	z-index: 1000;
}
.close {
	position: absolute;
	right: 5pt;
}
